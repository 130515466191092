var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-1 px-2 meeting-content mx-3 row"},[_c('div',{staticClass:"col-4"},[_c('img',{staticClass:"mr-2 mb-1",attrs:{"src":require("@/assets/icons/meeting-time.svg")}}),_c('label',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.getMeetingTime()))]),_vm._m(0),_c('img',{staticClass:"mr-1 secondary-meeting-info",attrs:{"src":require("@/assets/icons/lunch.svg")}}),_c('span',{staticClass:"secondary-meeting-info",attrs:{"title":_vm.meeting.appointment_label !== null
          ? _vm.meeting.appointment_label
          : _vm.meeting.refreshment}},[_vm._v(_vm._s(_vm.getMeetingRefreshment()))])]),(_vm.meeting.tenant_location)?_c('span',{staticClass:"mt-2 col-4 special-info d-flex justify-content-center",on:{"click":_vm.showPolicyAndPreference}},[_vm._v(" "+_vm._s(_vm.meeting.tenant_location.name ? _vm.meeting.tenant_location.name : "")+" ")]):_vm._e(),_c('div',{staticClass:"col-4 row d-flex justify-content-end"},[(!_vm.enableBookButton)?_c('span',{staticClass:"col-6 font-12 d-flex align-self-center justify-content-end secondary-meeting-info"},[_vm._v(" "+_vm._s("*Schedule open at ")+_vm._s(_vm.moment(this.scheduledOpenTime).format("DD MMM YYYY hh:mm A - z"))+" ")]):_vm._e(),(
        _vm.isMedicalRep &&
        _vm.rolePerm &&
        _vm.rolePerm[_vm.perm.module.MEETINGS] &&
        _vm.rolePerm[_vm.perm.module.MEETINGS].includes(_vm.perm.CREATE) &&
        _vm.isEnableQuickBook
      )?_c('b-button',{staticClass:"p-2 col-6 text-primary d-flex justify-content-end book-meeting-button",attrs:{"variant":"outline-none","disabled":!_vm.enableBookButton},on:{"click":function($event){return _vm.onQuickBookMeeting(_vm.meeting.id)}}},[_vm._v("1-Click Book")]):_vm._e(),(
        (_vm.rolePerm &&
          _vm.rolePerm[_vm.perm.module.MEETINGS] &&
          _vm.rolePerm[_vm.perm.module.MEETINGS].includes(_vm.perm.CREATE) &&
          !_vm.isEnableQuickBook) ||
        _vm.role === 'SUPER_ADMIN' ||
        _vm.role === 'ADMIN' ||
        _vm.role === 'PHYSICIAN'
      )?_c('b-button',{staticClass:"col-2 p-2 text-primary d-flex justify-content-end book-meeting-button",attrs:{"variant":"outline-none","disabled":!_vm.enableBookButton},on:{"click":function($event){return _vm.onBookMeeting(_vm.meeting.id)}}},[_vm._v("Book ")]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"mx-2"},[_c('img',{attrs:{"src":require("@/assets/icons/divider.svg")}})])}]

export { render, staticRenderFns }