












































import alert from "@/mixins/alert";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class MessageOrganizerModal extends Vue {
  @Prop({ default: "md" }) public size!: any;
  @Prop() public meeting!: any;
  @Prop({ default: ''}) public title!: string;
  @Prop({ default: 'Reason'}) public labelTitle!: string;
  @Prop() public modalId!: string;
  @Prop({ default: false}) public isReasonRequired!: boolean;
  reason = '';

  show() {
    this.$bvModal.show(this.modalId);
  }

  hide() {
    this.$bvModal.hide(this.modalId);
  }

  hideModal() {
    this.reason = '';
    this.hide();
  }

  onSubmit() {
    if(this.isReasonRequired && this.reason === '') {
      alert.methods.appToaster({message: `${this.labelTitle} is required`, type: "ERROR"})
    } else {
      this.$emit("onSubmit", {id: this.meeting.id, reason: this.reason});
      this.hideModal();
    }
  }

}
