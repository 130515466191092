
























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ValidationObserver } from "vee-validate";
import alert from "@/mixins/alert";
import constants from "@/constants/_message";
import DatePicker from "@/components/datepicker/index.vue";
import { isNotEmpty } from "@/utils/object";

@Component({
  components: {
    DatePicker
    }
})
export default class VaccinationModal extends Vue {
  @Action updateVaccinationStatus: any;
  @Getter currentUser: any;

  @Prop() isShowModal!: boolean;

  isSubmitted = false;
  fileName = null;
  fileAcceptCriteria = "jpeg,png,application/pdf";
  fileRules: any = `mimes:${this.fileAcceptCriteria}|size:10240`;
  fileAccept = "jpeg,png,application/pdf";
  disabled = false;
  warningMessageShow = false;

  vaccinationTypes = [
    {
      text: "Yes",
      value: 1,
    },
    {
      text: "No",
      value: 2,
    },
    {
      text: "Decline to answer",
      value: 0,
    },
  ];

  // noOfDoses = [
  //   {
  //     text: "1",
  //     value: 1,
  //   },
  //   {
  //     text: "2",
  //     value: 2,
  //   },
  //   {
  //     text: "3",
  //     value: 3,
  //   },
  //   {
  //     text: "Decline to answer",
  //     value: 0,
  //   },
  // ];
  repVaccination: any = {
    id: 0,
    vaccination_status: "",
    // doses: "",
    vaccination_certificate_url: "",
    last_date_vaccination: ''
  };
  $refs!: {
    vaccinationForm: InstanceType<typeof ValidationObserver>;
  };

  setVaccinationStatus(value: any) {
    this.repVaccination.vaccination_status = value;
    if(this.currentUser.tenant.required_reps_vaccination && (value === 2 || value === 0)) {
      this.disabled = true;
      this.warningMessageShow = true;
    } else {
      this.disabled = false;
      this.warningMessageShow = false;
    }
  }

  // setVaccinationDoses(value: any) {
  //   this.repVaccination.doses = value;
  //   if(this.currentUser.tenant.required_reps_vaccination && value < 2) {
  //     this.disabled = true;
  //     this.warningMessageShow = true;
  //   } else {
  //     this.disabled = false;
  //     this.warningMessageShow = false;
  //   }
  // }

  onFormSubmit() {
    this.isSubmitted = true;
    this.$refs.vaccinationForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (isNotEmpty(firstErrorFocused)) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.vaccinationForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.vaccinationForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }       
        return;
      } else {
        if(this.currentUser.tenant.tenantpreferences.required_fields.vaccination_certificate_url && (this.repVaccination.vaccination_certificate_url === null || this.repVaccination.vaccination_certificate_url == '')) {
          return alert.methods.appToaster({ message: 'Vaccination card is mandatory to continue booking appointments', type: "ERROR" })
        }
        this.repVaccination.id = this.currentUser.id;
        const vaccinationStatus = this.repVaccination;
        if(vaccinationStatus.vaccination_status === 2) {
          // vaccinationStatus.doses = '';
          vaccinationStatus.last_date_vaccination = '';
        }
        this.updateVaccinationStatus({
          data: vaccinationStatus,
          callback: () => {
            this.resetForm();
            this.$emit("onActionPerformed", true);
          },
        });
      }
    });
  }

  resetForm() {
    this.repVaccination = {
      id: 0,
      vaccination_status: "",
      // doses: "",
      vaccination_certificate_url: "",
      last_date_vaccination: ""
    };
    this.warningMessageShow = false;
  }

  setFileObj(value: any) {
    this.repVaccination.vaccination_certificate_url = value;
  }

  dismissModal() {
    this.resetForm();
    this.$emit("onActionPerformed", false);
  }

  closeModal() {
    this.$emit("onActionPerformed", true);
  }
}
