



























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    LetterIcon,
  },
})
export default class TransferForm extends Vue {
  @Prop() public currentUser: any;
  @Prop({ default: "lg" }) public size!: any;
  @Prop() public isTeamSelected!: any;
  @Prop() public teams: any;
  @Action public getUserListForDropdown: any;
  @Getter public users: any;
  @Action public setUsers: any;
  selectedTeam: any;
  searchText = "";
  userFilter: any = {
    where: {
        userTypesId: { eq: 3 },
        is_medical_rep: true,
        status: { eq: 1 },
        is_blocked: false,
        isSearch: true,
      and: [],
    },
    order: ["first_name ASC"],
  };
  
  show(): void {
    this.$bvModal.show("transfer-meeting-modal");
  }

  hide(): void {
    this.$bvModal.hide("transfer-meeting-modal");
  }

  getTeamMembers(team: any): void {
    this.isTeamSelected = !this.isTeamSelected;
    this.selectedTeam = team;
  }

  back(): void {
    this.isTeamSelected = !this.isTeamSelected;
    this.selectedTeam = null;
  }

  onInterCompanyTabClick() {
    this.searchText = '';
    this.setUsers([]);
  }

  onChangeFilterInput() {
    if (this.searchText.length >= 5) {
      this.userFilter.where.and = [
        { email: { ilike: `%${this.searchText}%` } },
        { email: { nlike: `%${this.currentUser.email.split("@")[1]}%` } },
      ];
      this.getUserListForDropdown(this.userFilter);
    } else {
      this.setUsers([]);
    }
  }

  isTransferEndable(member: any) {
    if (
      this.currentUser.tenant.tenantpreferences.required_fields
        .inter_company_transfer
    ) {
      return this.currentUser.id !== member.user.id ? true : false;
    } else {
      if (this.currentUser.id !== member.user.id) {
        return member.user.email.split("@")[1] ===
          this.currentUser.email.split("@")[1]
          ? true
          : false;
      } else {
        return false;
      }
    }
  }

  interCompanyTransfer(user: any) {
    this.$emit("onTransferSelected", {
      organizer: user,
    });
    this.hide();
  }

  onTransfer(user: any) {
    this.$emit("onTransferSelected", {
      organizer: user,
      teams_id: this.selectedTeam.id,
    });
    this.hide();
  }
}
