




























































































import { Component, Vue, Watch } from "vue-property-decorator";
import MeetingCard from "@/components/meetingCard/MeetingCard.vue";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import FilterComponent from "@/components/filterComponent/Index.vue";
import PolicyPreferenceModal from "@/components/policyPreferenceModal/PolicyPreferenceModal.vue"
import RestaurantModal from "@/components/restaurantModal/RestaurantModal.vue"
import { Action, Getter } from "vuex-class";
import moment from "moment";
import DateFilter from "@/components/dateFilter/index.vue";
import constants from "@/constants/app.properties";
import TransferForm from "@/components/transferForm/TransferForm.vue"
import alert from "@/mixins/alert";
import { createTransferMeetingPendingRequestAlert, deleteMeetingAlert } from "@/utils/notificationContent";
import CancelMeetingModal from "@/components/cancelMeetingModal/CancelMeetingModal.vue";
import MessageOrganizerModal from "@/components/messageOrganizerModal/MessageOrganizerModal.vue";
import BaseDropDownModal from "@/components/baseDropdownModal/BaseDropDownModal.vue";

@Component({
  components: {
    MeetingCard,
    AppModal,
    LetterIcon,
    FilterComponent,
    DateFilter,
    PolicyPreferenceModal,
    RestaurantModal,
    TransferForm,
    CancelMeetingModal,
    MessageOrganizerModal,
    BaseDropDownModal,
  },
})
export default class MeetingList extends Vue {
  @Action public getMeetingList: any;
  @Action public setActiveTab: any;
  @Action public getPoliciesAndPreferencesByLocationId: any;
  @Getter public policyPreferenceList: any;
  @Getter public meetings: any;
  @Getter public meeting: any;
  @Getter public permissions!: any;
  @Getter public role!: any;
  @Getter private currentUser!: any;
  @Action public getLocationDropdown: any;
  @Getter public locations: any;
  @Action public getTeams: any;
  @Getter public teams: any;
  @Action public transferMeeting: any;
  @Getter public isCurrentTenantBlocked: any;
  @Getter dropdownPracticeList: any;
  @Action public cancelMeetingWithReason: any;
  @Action public setRecommendedPhysicians: any;
  @Action public createTransferMeeting: any;
  @Action public setUsers: any;
  @Action public messageMeetingOrganizer: any;
  @Action public getRestaurants: any;
  @Getter public restaurants: any;
  @Action public updatePastMeetingById: any;


  meetingList: any = [];
  activeTabName = "UPCOMING";
  $refs!: {
    appModalRef: HTMLFormElement;
    policyPreferenceModalRef: HTMLFormElement;
    restaurantModalRef: HTMLFormElement;
    transferModal: HTMLFormElement;
    cancelMeetingModalRef: HTMLFormElement;
    messageOrganizerModalRef: HTMLFormElement;
    restaurantDropDownModal: HTMLFormElement;
  };
  attendeeList: any = [];
  restaurantDetails: any = {};
  searchText = "";
  searchLocation: any = "";
  fromDate = "";
  toDate = "";
  skip = 0;
  transferMeetingId = 0;
  isSelectedTeam = false;
  meetingToBeCancelled: any = {};
  meetingOption = 1;
  organizerIds: number[] = [];
  meetingOptions = [
    {
      name: 'All Meetings',
      id: 1
    },
    {
      name: 'My Meetings',
      id: 2
    }
  ]
  isPastMeeting = false;
  maxDate = "";
  order = 'DESC';
  restaurantsId = 0;
  meetingDetails: any = {};

  get isMedicalRep() {
    return localStorage.getItem(constants.IS_MEDICAL_REP);
  }

  beforeMount() {
    this.isPastMeeting = this.$route.path === '/past-meeting/';
    this.activeTabName = this.$store.state.meeting.activeTab;
    this.setRecommendedPhysicians([]);
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    this.searchLocation = Number(localStorage.getItem(constants.LOCATION_ID)) || null;
    let startTime = `${moment()
      .startOf("day")
      .format("YYYY-MM-DD")}T00:00:00.000Z`;
    let endTime = `${moment()
      .add(10, "days")
      .format("YYYY-MM-DD")}T23:59:59.999Z`;
    this.fromDate = startTime;
    this.toDate = endTime;
    this.maxDate = this.isPastMeeting ? this.fromDate : this.maxDate;
    this.setMeetingOptions(this.meetingOption)
    // this.getMeetings();
  }

  scroll() {
    const listElm = document.querySelector("#meeting-card");
    if (
      listElm &&
      listElm.childElementCount >= 20 &&
      listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
    ) {
      this.skip = this.skip + 20;
      this.getMeetings();
    }
  }

  @Watch("meetings")
  onMeetingsUpdated() {
    if (this.skip > 0) {
      this.meetingList = this.meetingList.concat(this.meetings);
    } else {
      this.meetingList = [...this.meetings];
    }
  }

  getDuration(duration: any) {
    const d = Number(duration);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    let hD = (h > 0 ? h : 0) + "h ";
    let mD = (m > 0 ? m : 0) + "m";

    return hD + mD;
  }

  getMeetings() {
    this.getMeetingList(this.getMeetingParamsObj());
  }

  getMeetingParamsObj(): URLSearchParams {
    const meetingParams = new URLSearchParams();
    meetingParams.set("limit", "20")
    meetingParams.set("skip", this.skip.toString())
    this.isPastMeeting ? meetingParams.set("before", this.fromDate) : meetingParams.set("after", this.fromDate);
    this.isPastMeeting && meetingParams.set("order", this.order)
    meetingParams.set("status", "BOOKED")
    if (this.searchText) {
      meetingParams.set("searchText", this.searchText)
    }
    if (this.searchLocation && this.searchLocation != "All") {
      meetingParams.set("locationId", this.searchLocation);
    }
    if (!this.isPastMeeting && 
    ((this.currentUser && this.currentUser.is_medical_rep) || localStorage.getItem(constants.IS_MEDICAL_REP))) {
      this.organizerIds.forEach((i) => {
        meetingParams.append("organizerIds", i.toString())
      });
    }
    return meetingParams;
  }

  @Watch('currentUser')
  onCurrentUserChanged() {
    this.setMeetingOptions(this.meetingOption);
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.skip = 0;
      this.meetingList = [];
      this.getMeetings();
    }
  }

  setMeetingOptions(value: any) {
    if (!this.isPastMeeting &&
    (this.currentUser && this.currentUser.is_medical_rep || localStorage.getItem(constants.IS_MEDICAL_REP))) {
      this.meetingOption = value;
      if (value === 1) {
        this.organizerIds = this.currentUser.teamMembers && this.currentUser.teamMembers.length > 0 ?
          this.currentUser.teamMembers : [localStorage.getItem(constants.USER_ID)];
      } else {
        this.organizerIds = [Number(localStorage.getItem(constants.USER_ID))];
      }
      this.getMeetings();
    } else {
      this.getMeetings();
    }
  }

  setLocation(value: any): any {
    this.searchLocation = value;
    this.skip = 0;
    this.meetingList = [];
    this.getMeetings();
  }

  openAttendeeListModal(list: any): void {
    this.attendeeList = list ? list : [];
    const appModal = this.$refs.appModalRef;
    appModal.show();
  }

  showPolicyAndPreference(locationId: any): void {
    const policyModal = this.$refs.policyPreferenceModalRef;
    this.getPoliciesAndPreferencesByLocationId({ data: locationId, callBack: () => policyModal.show() });
  }

  openCancelMeetingModal(meeting: any) {
    this.meetingToBeCancelled = meeting;
    const cancelMeetingModal = this.$refs.cancelMeetingModalRef;
    cancelMeetingModal.show();
  }

  openMessageOrganizerModal(meeting: any) {
    this.meetingToBeCancelled = meeting;
    const messageOrganizerModal = this.$refs.messageOrganizerModalRef;
    messageOrganizerModal.show();
  }

  showRestaurant(restaurant: any): void {
    this.restaurantDetails = restaurant ? restaurant : {};
    const restaurantModal = this.$refs.restaurantModalRef;
    restaurantModal.show();
  }

  openTransferModal(meetingId: any): void {
    this.transferMeetingId = meetingId;
    this.isSelectedTeam = false;
    this.getTeams({
      query: {
        where: { status: { eq: true } },
        include: [
          {
            relation: "teamMembers", scope: {
              where: { userId: localStorage.getItem(constants.USER_ID) },
              include: [{ relation: "user" }]
            }
          },
        ]
      }, callback: this.validateAndOpenModal
    });
  }

  validateAndOpenModal(allTeams: any) {
    if (allTeams.length) {
      if (allTeams.length === 1) {
        const teamMembers = allTeams[0].teamMembers.filter((member: any) => member.userId !== this.currentUser.id);
        if (!teamMembers.length) {
          return alert.methods.appToaster({
            message: 'There are no teams available for you to transfer meeting. Please create teams to proceed.',
            type: "ERROR",
          });
        }
      }
      const transferModal = this.$refs.transferModal;
      transferModal.show();
    } else {
      alert.methods.appToaster({
        message: 'There are no teams available for you to transfer meeting. Please create teams to proceed.',
        type: "ERROR",
      });
    }
  }

  onTransferMeeting(value: any): void {
    this.setUsers([]);
    const isSameDomain = value.organizer.email.split("@")[1] === this.currentUser.email.split("@")[1];
    if (!this.currentUser.tenant.tenantpreferences.required_fields.inter_company_transfer) {
      let data = {
        organizerId: value.organizer.id,
        teams_id: value.teams_id,
      }
      this.transferMeeting({ id: this.transferMeetingId, data: data, callback: this.getMeetings });
    } else {
      if (isSameDomain) {
        let data = {
          organizerId: value.organizer.id,
          teams_id: value.teams_id,
        }
        this.transferMeeting({ id: this.transferMeetingId, data: data, callback: this.getMeetings });
      } else {
        const msg = createTransferMeetingPendingRequestAlert.message.replace(
          "tenantName",
          `${this.currentUser.tenant.name}`
        );
        alert.methods.conFirmAlert({ ...createTransferMeetingPendingRequestAlert, message: msg },
          () => {
            let data = {
              id: this.transferMeetingId,
              to_organizer: value.organizer.id,
              transfer_status: "pending",
            };
            this.createTransferMeeting({ data: data, callback: this.getMeetings });
          },
          () => { }
        );
      }
    }
  }

  openMeetingHistoryModal(list: any): void {
    this.attendeeList = list ? list : [];
    const appModal = this.$refs.appModalRef;
    appModal.show();
  }

  dateFilterChanged(value: any) {
    this.meetingList = [];
    this.skip = 0;
    this.dateChanged(value);
  }

  refreshMeetingList() {
    this.meetingList = [];
    this.skip = 0;
    this.getMeetings();
  }

  dateChanged(value: any) {
    let date = value ? moment(value, "YYYY-MM-DD") : moment();
    this.fromDate = `${date.startOf("day").format("YYYY-MM-DD")}T00:00:00.000Z`;
    this.getMeetings();
  }

  destroyed() {
    this.setActiveTab(this.activeTabName === "UPCOMING");
  }

  onCancelMeeting(data: any) {
    alert.methods.conFirmAlert(
      deleteMeetingAlert,
      () => {
        this.cancelMeetingWithReason({
          data,
          callback: () => {
            this.refreshMeetingList();
          },
        });
      },
      () => { }
    );
  }

  messageOrganizer(data: any) {
    this.messageMeetingOrganizer(
      {
        meeting_id: data.id,
        body: data.reason
      }
    );
  }

  updateRestaurant(meeting: any): void {
    this.restaurantsId = meeting.restaurantsId;
    if (this.currentUser.is_medical_rep && this.currentUser?.tenant?.other_restaurant) {
      this.getRestaurants(
        {
          fields: ['id', 'name', 'status', 'display_order', 'city', 'state', 'country', 'zipcode'],
          order: ["display_order DESC", "name ASC"],
          where: { status: true },
        });
    } else {
      this.getRestaurants({
        fields: ['id', 'name', 'status', 'display_order', 'city', 'state', 'country', 'zipcode'],
        order: ["display_order DESC", "name ASC"],
        where: {
          status: true, and: [
            {
              id: { nin: [9999] },
            }
          ]
        },
      }
      );
    }
    this.meetingDetails = { id: meeting.id };
    const restaurantDropDownModal = this.$refs.restaurantDropDownModal;
    restaurantDropDownModal.show();
  }

  onUpdateMeeting(meeting: any): void {
    let other_restaurant = 'Unknown restaurant';
    if (meeting?.restaurantsId !== 9999) {
      other_restaurant = '';
    }
    this.updatePastMeetingById({
      data: {
        id: meeting.id,
        restaurantsId: meeting.restaurantsId, other_restaurant
      }, callback: () => this.refreshMeetingList()
    });
  }

}
