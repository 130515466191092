





































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class PolicyPreferenceModal extends Vue {
  @Prop({ default: "md" }) public size!: any;
  @Prop() public meeting!: any;

  get addressDetails() {
    let addressStr = this.meeting.restaurant.address;
    if (this.meeting.restaurant.city) {
      addressStr = `${addressStr}, ${this.meeting.restaurant.city}`;
    }
    if (this.meeting.restaurant.state) {
      addressStr = `${addressStr}, ${this.meeting.restaurant.state}`;
    }
    if (this.meeting.restaurant.zipcode) {
      addressStr = `${addressStr}, ${this.meeting.restaurant.zipcode}`;
    }
    return addressStr;
  }

  show() {
    this.$bvModal.show("meeting-restaurant-modal");
  }

  hide() {
    this.$bvModal.hide("meeting-restaurant-modal");
  }

}
