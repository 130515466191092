





































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import BookMeetingCard from "@/components/bookMeetingCard/BookMeetingCard.vue";
import PolicyPreferenceModal from "@/components/policyPreferenceModal/PolicyPreferenceModal.vue"
import DateFilter from "@/components/dateFilter/index.vue";
import constants from "@/constants/app.properties";
import VaccinationModal from "@/components/vaccinationModal/VaccinationModal.vue";
import RecommendationModal from "@/components/recommendationModal/RecommendationInfoModal.vue";
import _permissions from "@/constants/_permissions";

@Component({ components: { DateFilter, BookMeetingCard, PolicyPreferenceModal, VaccinationModal, RecommendationModal } })
export default class BookMeetingList extends Vue {
  @Action public getLocationDropdown: any;
  @Action public getMeetingList: any;
  @Action public getPoliciesAndPreferencesByLocationId: any;
  @Getter public meetings: any;
  @Getter public locations: any;
  @Getter private currentUser!: any;
  @Getter public permissions!: any;
  @Getter public role!: any;
  @Getter public policyPreferenceList: any;
  @Getter public meetingId: any;
  @Action public setMeetingId: any;
  @Action public getRecommendedMeetingList: any;
  @Getter isShowRecommendationModal!: boolean;
  @Action hideRecommendationModal: any;
  @Action setRecommendedPhysicians: any;
  @Action private getLoggedInUser: any;
  @Getter public rolePerm: any
  perm = _permissions;

  searchLocation: any = "";
  meetingList: any = [];
  fromDate = "";
  minDate =`${moment().startOf("day").format("YYYY-MM-DD")}T00:00:00.000Z`;
  $refs!: {
    policyPreferenceModalRef: HTMLFormElement;
  };

  meetingParams: any = {};
  skip = 0;
  isModalOpen = false;

  beforeMount() {
    this.hideRecommendationModal();
    this.setRecommendedPhysicians([]);
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    this.searchLocation = Number(localStorage.getItem(constants.LOCATION_ID)) || null;
    let startTime = `${moment()
      .startOf("day")
      .format("YYYY-MM-DD")}T00:00:00.000Z`;
    this.fromDate = startTime;
    this.getMeetings();
  }

  scroll() {
    const listElm = document.querySelector("#meeting-card");
    if (
      listElm &&
      listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
    ) {
      this.skip = this.skip + 20;
      this.getMeetings();
    }
  }

  @Watch("meetings")
  onMeetingsUpdated() {
    if(this.skip > 0) {
      this.meetingList = this.meetingList.concat(this.meetings);
    } else {
      this.meetingList = [...this.meetings];
    }
  }

  onMeetingsRemoved(id:any) {
    if(id){
      this.meetingList = this.meetingList.length > 0 && this.meetingList.filter((v:any)=>v.id!=id);
    }
  }

  getMeetings() {
    this.getMeetingList(this.getMeetingParamsObj());
  }

  getMeetingParamsObj(): any {
    this.meetingParams = {
      limit: 20,
      skip: this.skip,
      after: this.fromDate,
      status: 'AVAILABLE',
    };
    if (this.searchLocation && this.searchLocation != "All") {
      this.meetingParams.locationId = this.searchLocation;
    } else {
      delete this.meetingParams.locationId;
    }
    return this.meetingParams;
  }

  onBookMeeting(id: any) {
    this.setMeetingId(id);
    this.isModalOpen = true;
  }

  onActionPerformed(params: any) {
    this.isModalOpen = false;
    if(params) {
      if(this.isEnableQuickBook){
        this.getLoggedInUser();
      } else {
        this.$router.push({ path: `/book-meeting/update/${this.meetingId}` });
      }
      this.setMeetingId(0);
    }
  }


  setLocation(value: any): any {
    this.searchLocation = value;
    this.skip = 0;
    this.meetingList = [];
    this.getMeetings();
  }

  dateFilterChanged(value: any) {
    this.meetingList = [];
    this.skip = 0;
    this.dateChanged(value);
  }

  dateChanged(value: any) {
    let date = value ? moment(value, "YYYY-MM-DD") : moment();
    this.fromDate = `${date.startOf("day").format("YYYY-MM-DD")}T00:00:00.000Z`;
    this.getMeetings();
  }

  getFormattedDate(value: any) {
    return moment(value.start_time).format("DD MMM YYYY");
  }

  getMeetingDay(value: any) {
    return moment(value.start_time).format("ddd");
  }

  showPolicyAndPreference(locationId: any): void {
    const policyModal = this.$refs.policyPreferenceModalRef;
    this.getPoliciesAndPreferencesByLocationId({data:locationId, callBack: () => policyModal.show()});
  }

  showRecommendedMeetings() {
    this.getRecommendedMeetingList(this.currentUser.id);
  }

  get isEnableQuickBook(){
    return this.currentUser.tenant &&
    this.currentUser.tenant.tenantpreferences.required_fields &&
    this.currentUser.tenant.tenantpreferences.required_fields.enable_quick_book;
  }
}
