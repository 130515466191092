































import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import alert from "@/mixins/alert";
import { isNotEmpty } from "@/utils/object";
import constants from "@/constants/_message";

@Component({})
export default class BaseDropDownModal extends Vue {
  @Prop() public title!: string | null;
  @Prop({ default: "md" }) public size!: string;
  @Prop({ default: "" }) contentClass!: string;
  @Prop({ default: false }) hideHeader!: boolean;
  @Prop({ default: false }) public noCloseOnBackdrop!: boolean;
  @Prop({ default: false }) public noCloseOnEsc!: boolean;
  @Prop({ default: [] }) options!: string[];
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: "" }) textField!: string;
  @Prop({ default: 0 }) valueField!: number;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: 0 }) selectedVal!: number;
  @Prop({ default: false }) public meetingRes!: boolean;
  @Prop() public meetingDetails!: any;

  baseDropDownVal: any = {};
  isSubmitted = false;

  $refs!: {
    baseModelForm: InstanceType<typeof ValidationObserver>;
  };

  show() {
    this.$bvModal.show("app-dropdown-modal");
  }

  hide() {
    this.baseDropDownVal = {};
    this.$bvModal.hide("app-dropdown-modal");
  }

  onCancel() {
    this.baseDropDownVal = {};
    this.hide();
  }

  onFormSubmit() {
    this.isSubmitted = true;
    this.$refs.baseModelForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.baseModelForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.baseModelForm.refs[
            errors[0]["key"]
          ].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.$emit("onUpdateMeeting", {
          id: this.meetingDetails.id,
          restaurantsId: this.baseDropDownVal.value ?? this.selectedVal
        });
        this.hide();
      }
    });
  }

  setBaseDropDownValue(value: any): any {
    this.baseDropDownVal.value = value;
  }

}
