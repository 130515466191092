









































































































import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import BookMeetingCard from "@/components/bookMeetingCard/BookMeetingCard.vue";
import alert from "@/mixins/alert";

@Component({ components: { BookMeetingCard } })
export default class RecommendationModal extends Vue {
  @Action closeInfoModal!: any;
  @Action private logout: any;
  @Getter isShowRecommendationModal!: boolean;
  @Getter recommendations: any;
  @Getter currentUser: any;
  @Getter isCurrentTenantBlocked: any;
  @Action public meetingRuleValidation: any;
  @Action setRecommendedPhysicians: any;
  @Action public showHipaaAgreementModal: any;

  meetingId = 0;

  getMeetingTime(meeting: any) {
    return `${moment(meeting.start_time).format("hh:mm A")} - ${moment(
          meeting.end_time
        ).format("hh:mm A")}`;
    // return this.meeting
    //   ? `${moment(this.meeting.start_time).isDST()} - ${moment(this.meeting.start_time).format("hh:mm A")} - ${moment(
    //       this.meeting.end_time
    //     ).format("hh:mm A")}`
    //   : "";
  }

  getMeetingRefreshment(meeting: any) {
    if (meeting.appointment_label && meeting.appointment_label !== null) {
      return meeting.appointment_label && meeting.appointment_label.length >= 30
        ? meeting.appointment_label.slice(0, 30) + "..."
        : meeting.appointment_label;
    } else {
      return meeting.refreshment && meeting.refreshment.length >= 30
        ? meeting.refreshment.slice(0, 30) + "..."
        : meeting.refreshment;
    }
  }

  onBookMeeting(meeting: any) {
    if(this.currentUser.is_medical_rep) {
      let currentTime = moment.utc(
        moment().format("YYYY-MM-DD[T]HH:mm[Z]"),
        "YYYY-MM-DD[T]HH:mm[Z]"
      );
      let subscribtionStartDate = moment.utc(
        this.currentUser.tenant.subscription_start_date,
        "YYYY-MM-DD[T]HH:mm[Z]"
      );
      if (this.currentUser.tenant.subscription_grace_days) {
        subscribtionStartDate = subscribtionStartDate.add(
          this.currentUser.tenant.subscription_grace_days,
          "days"
        );
      }
      if (
        this.currentUser.userTypesId === 3 &&
        this.currentUser.tenant.enable_subscription &&
        currentTime.isSameOrAfter(subscribtionStartDate)
      ) {
        if (
          this.currentUser.subscription_end_date &&
          this.currentUser.subscription_end_date !== ""
        ) {
          let subscriptionTime = moment.utc(
            this.currentUser.subscription_end_date,
            "YYYY-MM-DD[T]HH:mm[Z]"
          );
          if (this.currentUser.tenant.subscription_grace_days) {
            subscriptionTime = subscriptionTime.add(
              this.currentUser.tenant.subscription_grace_days,
              "days"
            );
          }
          subscriptionTime.isSameOrAfter(currentTime)
            ? this.isHipaaSigned(meeting)
            : alert.methods.appToaster({
                message:
                  "Your subscription is expired, Renew subscription to continue our services",
                type: "ERROR",
              });
        } else {
          alert.methods.appToaster({
            message: "You don't have a subscribed plan. Kindly subscribe.",
            type: "WARNING",
            noAutoClose: true,
          });
        }
      } else {
          this.isHipaaSigned(meeting);
      }
    } else {
      this.validateMeetingRule(meeting.id);
    }
  }

  isHipaaSigned(meeting: any) {
    if (this.currentUser.tenant.tenantpreferences.required_fields.enable_hipaa_form 
        && this.currentUser.tenant.tenantpreferences.required_fields.sign_hipaa_form 
        && ((!this.currentUser.userhipaaform.hipaa_form_url) || (this.currentUser.userhipaaform.hipaa_form_url && this.currentUser.userhipaaform.hipaa_form_url.length == 0))
    ) {
      this.showHipaaAgreementModal();
    } else {
      this.isVaccinationStatusNeeded(meeting);
    }
  }

  isVaccinationStatusNeeded(meeting: any) {
    this.setRecommendedPhysicians(meeting.physicianids);
    if (
      this.currentUser.is_medical_rep &&
      this.currentUser.tenant.covid_vaccination !== null
    ) {
      if (this.currentUser.tenant.required_reps_vaccination) {
        if (
          this.currentUser.tenant.tenantpreferences.required_fields
            .vaccination_certificate_url
        ) {
          if (
            this.currentUser.vaccination_status === 1 &&
            this.currentUser.vaccination_certificate_url !== "" &&
            this.currentUser.vaccination_certificate_url !== null
          ) {
            this.validateMeetingRule(meeting.id);
          } else if (
            this.currentUser.vaccination_status === null ||
            (this.currentUser.vaccination_status == "" &&
              this.currentUser.vaccination_status !== 0)
          ) {
            this.$emit("onBookMeeting", meeting.id);
          } else {
            alert.methods.appToaster({
              message:
                "Vaccination and vaccination card are mandatory to continue booking appointments, Go to profile screen to update your vaccination status.",
              type: "ERROR",
            });
          }
        } else {
          if (this.currentUser.vaccination_status === 1) {
            this.validateMeetingRule(meeting.id);
          } else if (
            this.currentUser.vaccination_status === null ||
            this.currentUser.vaccination_status == 0 ||
            this.currentUser.vaccination_status == 2
          ) {
            this.$emit("onBookMeeting", meeting.id);
          } else {
            alert.methods.appToaster({
              message:
                "Vaccination is mandatory to continue booking appointments, Go to profile screen to update your vaccination status.",
              type: "ERROR",
            });
          }
        }
      } else {
        if (
          this.currentUser.vaccination_status ||
          this.currentUser.vaccination_status == 0
        ) {
          this.validateMeetingRule(meeting.id);
        } else {
          this.setRecommendedPhysicians(meeting.physicianids);
          this.$emit("onBookMeeting", meeting.id);
        }
      }
    } else {
      this.validateMeetingRule(meeting.id);
    }
  }

  validateMeetingRule(id: any) {
    this.meetingId = id;
    if (this.currentUser.tenant.tenantpreferences.enable_rule) {
      this.meetingRuleValidation({
        data: { id },
        callback: this.ruleValidationSccess,
      });
    } else {
      this.$router
        .push({ path: `/book-meeting/update/${this.meetingId}` })
        .catch();
    }
  }

  ruleValidationSccess(state: any) {
    if (state) {
      this.$router
        .push({ path: `/book-meeting/update/${this.meetingId}` })
        .catch();
    }
  }
}
